/* eslint-disable no-shadow */
import { useEmailPreview } from '../../../contexts/EmailTemplate';
/* eslint-disable func-names */

function UploadFile({ show }) {
    const { setHtml, handleFileSelect } = useEmailPreview();
    const handleFileRead = (e) => {
        const content = e.target.result;
        setHtml(content);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onloadend = handleFileRead;
        reader.readAsText(file);
    };

    if (show) {
        return null;
    }
    return (
        <div
            className="select-file bg-[rgba(174,0,185,0.05)] absolute w-[406px] h-44 border-[1px] border-[#AE00B9]/30 border-dashed rounded-[10px] flex flex-row items-center justify-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <label
                htmlFor="selectFile"
                className="w-full h-full flex items-center justify-center cursor-pointer"
            >
                <p className="font-poppins font-normal text-sm text-[#5A577A]">
                    <span className="text-[#AE00B9]">Select a file</span> or drag it here
                </p>
                <input
                    type="file"
                    id="selectFile"
                    accept="text/html,text/plain"
                    hidden
                    multiple
                    onChange={handleFileSelect}
                />
            </label>
        </div>
    );
}

export default UploadFile;
