/* eslint-disable no-unused-expressions */
import { useRef } from 'react';
import { useEmailPreview } from '../../../contexts/EmailTemplate';

const OutputBox = () => {
    const { html, templateWidth } = useEmailPreview();
    const previewFrame = useRef();

    return (
        <div className="w-1/2 bg-[#F3F3F5]/50 flex justify-center">
            <div className={`result w-[${templateWidth}] h-full overflow-hidden`}>
                <iframe
                    title="Preview"
                    srcDoc={`<html><body>${html}</body></html>`}
                    frameBorder="0"
                    className="w-full h-full"
                    ref={previewFrame}
                />
            </div>
        </div>
    );
};
export default OutputBox;
