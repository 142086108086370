import { deviceIcon } from '../../../../configs/Icons';
import { useEmailPreview } from '../../../contexts/EmailTemplate';

function Responsive() {
    const {
        handlerLaptopWidth,
        handlerTabletWidth,
        handlerMobileWidth,
        isActive,
        handlerModalShow
    } = useEmailPreview();
    return (
        <div className="preview-section w-1/2 flex flex-row items-center justify-between">
            <div className="responsive-icon flex flex-row items-center flex-auto justify-center gap-7">
                <button
                    type="button"
                    className={`laptop-device cursor-pointer hover:bg-[#FBF2FB] p-[7px] rounded-md ${
                        isActive === 'laptop' ? 'active' : ''
                    }`}
                    onClick={handlerLaptopWidth}
                >
                    <img src={deviceIcon.laptopIcon} alt="Laptop" />
                </button>
                <button
                    type="button"
                    className={`tablet-device cursor-pointer hover:bg-[#FBF2FB] p-[7px] rounded-md ${
                        isActive === 'tablet' ? 'active' : ''
                    }`}
                    onClick={handlerTabletWidth}
                >
                    <img src={deviceIcon.tabletIcon} alt="Tablet" />
                </button>
                <button
                    type="button"
                    className={`mobile-device cursor-pointer hover:bg-[#FBF2FB] p-[7px] rounded-md ${
                        isActive === 'mobile' ? 'active' : ''
                    }`}
                    onClick={handlerMobileWidth}
                >
                    <img src={deviceIcon.mobileIcon} alt="Mobile" />
                </button>
            </div>
            <div className="test-file pr-6 flex items-center gap-3">
                <button
                    type="button"
                    className="font-medium text-sm px-[10px] py-[5px] rounded-[5px] bg-secondary-50 text-secondary-700 hover:bg-secondary-900 hover:text-white transition-all duration-100"
                    onClick={handlerModalShow}
                >
                    Configuration
                </button>
                <button
                    type="button"
                    className="font-medium text-sm text-white bg-secondary-900 p-[5px_10px] rounded-[5px]"
                >
                    Test Email
                </button>
            </div>
        </div>
    );
}

export default Responsive;
