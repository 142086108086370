/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import ConfigApi from '../../../configs/ConfigApi';
import { useEmailPreview } from '../../contexts/EmailTemplate';
import AxiosAuth from '../../utils/AxiosAuth';

const EmailConfigPopup = () => {
    const { handlerModalRemove, showModal, chkNumber } = useEmailPreview();
    const [showPassword, setShowPassword] = useState(true);
    const handlerShowHide = () => {
        setShowPassword(!showPassword);
    };
    // Set Input Data
    const [hostName, setHostName] = useState('');
    const [port, setPort] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [emails, setEmails] = useState([]);

    const handlerSave = () => {
        const data = {
            host_name: hostName,
            port_number: port,
            user_name: userName,
            password,
            list_email: emails
        };
        AxiosAuth.post(ConfigApi.AUTH, data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (!showModal) {
        return null;
    }
    return (
        <div className="modal-box bg-primary-color/40 h-full w-full left-0 top-0 z-[2000] fixed">
            <div className="modal-box-content fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[420px] flex flex-col bg-white rounded-[25px] min-h-[185px] overflow-hidden shadow-[0px_4px_50px] shadow-[rgba(19,15,64,0.1)] ">
                <div className="modal-box-header flex justify-between items-center border-b border-primary-color/10 px-6 py-5">
                    <div className="modal-box-title">
                        <h3 className="text-primary-color font-medium font-poppins text-3xl">
                            Configuration
                        </h3>
                    </div>
                    <div className="modal-box-close">
                        <button
                            type="button"
                            className="closeBtn bg-close-icon hover:bg-close-icon-hover bg-[length:30px_30px] w-[30px] h-[30px] transition-all"
                            onClick={handlerModalRemove}
                        />
                    </div>
                </div>
                <div className="modal-body px-6 pt-4">
                    <div className="flex flex-wrap flex-row justify-between items-center gap-3">
                        <div className="host-name w-60">
                            <label htmlFor="hostName" className="flex flex-col">
                                <span className="inline-block text-base font-medium leading-9">
                                    Host Name
                                </span>
                                <input
                                    type="text"
                                    name="hostName"
                                    id="hostName"
                                    className="outline-none border border-primary-color/20 rounded-lg p-[10px] pl-4 font-normal text-sm text-primary-color placeholder:text-primary-color/30"
                                    placeholder="Ex: ns1.domain.com"
                                    onChange={(e) => setHostName(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className="port w-[120px]">
                            <label htmlFor="port" className="flex flex-col">
                                <span className="inline-block text-base font-medium leading-9">
                                    Port
                                </span>
                                <input
                                    type="text"
                                    name="port"
                                    id="port"
                                    className="outline-none border border-primary-color/20 rounded-lg p-[10px] pl-4 font-normal text-sm text-primary-color placeholder:text-primary-color/30"
                                    placeholder="1234"
                                    onKeyPress={chkNumber}
                                    onChange={(e) => setPort(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className="email-user-name w-full">
                            <label htmlFor="emailUserName" className="flex flex-col">
                                <span className="inline-block text-base font-medium leading-9">
                                    Email/User Name
                                </span>
                                <input
                                    type="text"
                                    name="emailUserName"
                                    id="emailUserName"
                                    className="outline-none border border-primary-color/20 rounded-lg p-[10px] pl-4 font-normal text-sm text-primary-color placeholder:text-primary-color/30"
                                    placeholder="yourname@email.com"
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className="password w-full">
                            <label htmlFor="password" className="flex flex-col relative">
                                <span className="inline-block text-base font-medium leading-9">
                                    Password
                                </span>
                                <input
                                    type={showPassword ? 'password' : 'text'}
                                    name="password"
                                    id="password"
                                    className="outline-none border border-primary-color/20 rounded-lg p-[10px] pl-4 font-normal text-sm text-primary-color placeholder:text-primary-color/30 placeholder:text-sm"
                                    placeholder="••••••••••••"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className={`showPassword absolute right-2 top-2/4 translate-y-1/3  bg-[length:22px_22px] w-[22px] h-[22px] outline-none ${
                                        showPassword ? 'bg-hide-icon' : 'bg-show-icon'
                                    }`}
                                    onClick={handlerShowHide}
                                />
                            </label>
                        </div>
                        <div className="test-email w-full">
                            <label htmlFor="testEmail" className=" text-base font-medium leading-9">
                                Test Email
                            </label>
                            <ReactMultiEmail
                                placeholder="yourname@email.com"
                                id="testEmail"
                                className=""
                                autoFocus={false}
                                emails={emails}
                                onChange={(_emails) => {
                                    setEmails(_emails);
                                }}
                                getLabel={(email, index, removeEmail) => (
                                    <div data-tag key={index}>
                                        <div data-tag-item>{email}</div>
                                        <button
                                            type="button"
                                            data-tag-handle
                                            onClick={() => removeEmail(index)}
                                        >
                                            ×
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer px-6 pb-6 pt-5 flex flex-row justify-end">
                    <button
                        type="button"
                        className="bg-[#17DC69] text-sm text-center text-white font-medium p-[10px] rounded-lg outline-none min-w-[120px]"
                        onClick={handlerSave}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmailConfigPopup;
