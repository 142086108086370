import React from 'react';
import EmailTemplateProvider from '../contexts/EmailTemplate';
import TemplatePreviewer from './template_previewer/TemplatePreviewer';

function IndexPage() {
    // const [inputText, setInputText] = useState('');
    return (
        <EmailTemplateProvider>
            <TemplatePreviewer />
        </EmailTemplateProvider>
    );
}

export default IndexPage;
