import React from 'react';
import Editor from './editor_container/Editor';
import Header from './editor_header/Header';
import EmailConfigPopup from './EmailConfigPopup';

const TemplatePreviewer = () => (
    <div className="main">
        {/* <HeaderSection /> */}
        <div className="main-section sm:h-screen">
            <div className="container mx-auto max-w-[1920px] p-[0!important]">
                <Header />
                <Editor />
                <EmailConfigPopup />
            </div>
        </div>
    </div>
);

export default TemplatePreviewer;
