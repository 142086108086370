import { useEmailPreview } from '../../../contexts/EmailTemplate';
import UploadFile from '../editor_header/UploadFile';

export const StateClearBtn = ({ show }) => {
    const { clearStateBtn } = useEmailPreview();
    // console.log('Ok');
    if (!show) {
        return null;
    }
    return (
        <button
            type="button"
            className="absolute right-5 top-5 z-[100] cursor-pointer bg-clear-state hover:bg-clear-state-hover w-10 h-10 bg-[length:40px_40px]"
            onClick={clearStateBtn}
        />
    );
};
const TextBoxArea = () => {
    const { html, handleSetHtml } = useEmailPreview();

    // console.log(html.length);

    return (
        <div className="w-1/2 border-r border-[#E8E7EC]">
            <div className="w-full overflow-auto relative">
                <StateClearBtn show={html.length} />
                <UploadFile show={html.length} />
                <textarea
                    rows="10"
                    cols="10"
                    onChange={handleSetHtml}
                    spellCheck="false"
                    value={html}
                    className="outline-none w-full min-h-[calc(100vh_-_79px)] max-h-screen p-5 scrollbar-hide font-normal bg-white resize-none z-30"
                    disabled={html.length === 0}
                />
            </div>
        </div>
    );
};
export default TextBoxArea;
