import { createContext, useContext, useState } from 'react';

export const EmailContext = createContext();
export const useEmailPreview = () => useContext(EmailContext);

const EmailTemplateProvider = ({ children }) => {
    const [templateWidth, setTemplateWidth] = useState('1366px');
    const [html, setHtml] = useState('');
    const [isActive, setIsActive] = useState('');
    const handlerLaptopWidth = () => {
        setTemplateWidth('1366px');
        setIsActive('laptop');
    };
    const handlerTabletWidth = () => {
        setTemplateWidth('991px');
        setIsActive('tablet');
    };
    const handlerMobileWidth = () => {
        setTemplateWidth('576px');
        setIsActive('mobile');
    };
    // Html preview
    const handleSetHtml = (event) => {
        setHtml(event.target.value);
    };
    // ref
    const handleFileSelect = (event) => {
        // const validFileType = ['.html', '.txt'];
        const file = event.target.files[0];
        if (file.type === 'text/plain' || file.type === 'text/html') {
            const reader = new FileReader();
            reader.onload = () => {
                const fileText = event.target.result;
                setHtml(fileText);
            };
            reader.readAsText(file);
        } else {
            // eslint-disable-next-line no-alert
            alert('Only HTML and Text file can uploaded.');
            setHtml(``);
        }
    };
    const clearStateBtn = () => {
        setHtml('');
    };

    const [showModal, setShowModal] = useState(false);
    const handlerModalRemove = () => {
        setShowModal(!showModal);
    };
    const handlerModalShow = () => {
        setShowModal(true);
    };
    const chkNumber = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };
    const emailValid =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const EmailCtx = {
        emailValid,
        handlerLaptopWidth,
        handlerTabletWidth,
        handlerMobileWidth,
        templateWidth,
        handleSetHtml,
        html,
        setHtml,
        setIsActive,
        isActive,
        // upload file data
        handleFileSelect,
        clearStateBtn,
        handlerModalRemove,
        handlerModalShow,
        showModal,
        chkNumber
    };

    return <EmailContext.Provider value={EmailCtx}>{children}</EmailContext.Provider>;
};

export default EmailTemplateProvider;
