/* eslint-disable import/extensions */
import React from 'react';
import { icons } from '../../../../configs/Icons';
import Responsive from './Responsive';

function Header() {
    return (
        <div className="editor-header w-full flex flex-row justify-between items-center border-t border-b border-primary-color/10 ">
            <div className="code-section flex flex-row flex-auto justify-between py-3 px-6 border-r border-primary-color/10">
                <div className="header-logo flex-auto">
                    <span className="w-28 sm:w-36 md:w-40 block">
                        <img src={icons.mainLogo} alt="Bikiran" className="w-full block" />
                    </span>
                </div>
            </div>
            <Responsive />
        </div>
    );
}

export default Header;
