import React from 'react';
import OutputBox from './OutputBox';
import TextBoxArea from './TextBoxArea';

const Editor = () => (
    <div className="content-area flex flex-row min-h-[calc(100vh_-_78px)]">
        <TextBoxArea />
        <OutputBox />
    </div>
);

export default Editor;
